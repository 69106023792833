import jsPlyr from './plyr.js'

"use strict";

const toggle = {
    elements: {
        open: '.js-modal-open',
    },
    init() {
        const self = this;
        const $open = document.querySelectorAll(self.elements.open);
        const $close = document.querySelectorAll(self.elements.close);

        $open.forEach($el => {
            $el.addEventListener('click', function (e) {
                e.preventDefault();
                const slug = this.dataset.modalSlug;

                self.open(slug);
            });
        });
    },

    open(slug) {

        // Get content from <template> with slug and render it
        const $template = document.querySelector(`template[data-modal-slug="${slug}"]`);
        const $content = $template.content.cloneNode(true);

        // Create modal
        const $modal = document.createElement('div');
        $modal.classList.add('modal');

        // Add overlay
        const $overlay = document.createElement('div');
        $overlay.classList.add('modal-overlay');
        $overlay.addEventListener('click', function () {
            $modal.remove();
        });

        $modal.appendChild($overlay);

        // Add container
        const $container = document.createElement('div');
        $container.classList.add('modal-container');
        $container.appendChild($content);
        $modal.appendChild($container);


        // Add close button
        const $close = document.createElement('button');
        $close.classList.add('modal-close');
        $close.innerHTML = '<span class="sr-only">Close</span>';
        $close.addEventListener('click', function () {
            $modal.remove();
        });

        $container.appendChild($close);

        // Append modal to body
        document.body.appendChild($modal);

        jsPlyr.init();
    },

};

export default toggle;
