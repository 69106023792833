"use strict";

import { Gradient } from "whatamesh";

const gradient = {
    gradients: [],
    element: '.js-gradient',
    init() {
        const self = this;
        const $gradients = document.querySelectorAll(self.element);

        if (!$gradients.length) {
            return;
        }

        $gradients.forEach(($gradient) => {
            const id = $gradient.getAttribute('id')
            const gradient = new Gradient()
            self.gradients.push(gradient)
            

            gradient.initGradient('#' + id)
        })
    },
};

export default gradient;
