import Alpine from 'alpinejs'
import htmx from 'htmx.org'
import lazy from './js/lazy.js'
import header from './js/header.js'
import animations from './js/animations.js'
import ras from './js/ras.js'
import toggle from './js/toggle.js'
import modal from './js/modal.js'
import slider from './js/slider.js'
import gradient from './js/gradient.js'
import jsPlyr from './js/plyr.js'

window.Alpine = Alpine
Alpine.start()

ras.init();
lazy.init();
header.init();
animations.init();
toggle.init();
modal.init();
slider.init();
gradient.init();
jsPlyr.init();


function htmxBefore(evt) {
  header.close();
  const $navItems = document.querySelectorAll('.js-htmx-nav-item');
  const $navGroups = document.querySelectorAll('.js-htmx-nav-group');

  $navItems.forEach($navItem => {
    $navItem.classList.remove('is-active');
  });

  $navGroups.forEach($navGroup => {
    $navGroup.classList.remove('is-active');
  });

  if (evt.target.classList.contains('js-htmx-nav-item')) {
    evt.target.classList.add('is-active');

    const navGroupSlug = evt.target.dataset.navGroupSlug;

    if (navGroupSlug) {
      const $navGroup = document.querySelector(`.js-htmx-nav-group[data-nav-group-slug="${navGroupSlug}"]`);
      $navGroup.classList.add('is-active');
    }
  }

}

function htmxAfter(evt) {
  lazy.init();
  animations.init();
  toggle.reset();
  modal.init();
  slider.init();
  gradient.init();
  jsPlyr.reset();

  if (evt.target.classList.contains('js-htmx-main')) {
    setTimeout(() => {

    }, 500);
    window.scrollTo(0, 0);
  }
}

htmx.on("htmx:beforeSend", function(evt) {
  console.log('htmx:beforeSend');
  htmxBefore(evt);
});

htmx.on("htmx:historyRestore", function(evt) {
  console.log('htmx:historyRestore');
  htmxAfter(evt);
});

htmx.on("htmx:afterSwap", function(evt) {
  console.log('htmx:afterSwap');
  htmxAfter(evt);
});