"use strict";
import EmblaCarousel from 'embla-carousel'

const slider = {
    elements: {
    },
   
    init() {
        const emblaNode = document.querySelector('.js-slider')

        if (!emblaNode) return;

        const options = { loop: false }
        const emblaApi = EmblaCarousel(emblaNode, options)
        console.log(emblaApi.slideNodes()) // Access API
    },
};

export default slider;
