const header = {
    elements: {
        nav: '.js-header-nav',
        navToggle: '.js-header-nav-toggle',
    },
    init() {
        const self = this;

        const $nav = document.querySelector(self.elements.nav);
        const $navToggle = document.querySelector(self.elements.navToggle);

        if (!$nav || !$navToggle) {
            return;
        }

        $navToggle.addEventListener('click', () => {
            $nav.classList.toggle('is-open');
            $navToggle.classList.toggle('is-active');
        });
    },
    close() {
        const self = this;


        const $nav = document.querySelector(self.elements.nav);
        const $navToggle = document.querySelector(self.elements.navToggle);

        if (!$nav || !$navToggle) {
            return;
        }

        $nav.classList.remove('is-open');
        $navToggle.classList.remove('is-active');
    }
};

export default header